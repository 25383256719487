import { useTheme } from 'styled-components';

import { TypographyType } from '../types';
import { StyledBody } from './styles';

type SizeType = 'large' | 'medium' | 'small';
interface BodyProps extends TypographyType {
  size?: SizeType;
}

export const Body = ({
  children,
  size = 'large',
  customSize,
  customWeight,
  customLineHeight,
  customFontFamily,
  ...rest
}: BodyProps) => {
  const theme = useTheme();
  const fontSizeMap = {
    large: 18,
    medium: 16,
    small: 14,
  };

  return (
    <StyledBody
      fontSize={customSize || fontSizeMap[size]}
      fontFamily={customFontFamily || theme.fonts.family.secondary}
      fontWeight={customWeight}
      lineHeight={customLineHeight}
      {...rest}
    >
      {children}
    </StyledBody>
  );
};
