import { useTheme } from 'styled-components';

import { TypographyType } from '../types';
import { StyledTitle } from './styles';

type SizeType = 'large' | 'medium' | 'small' | 'xs';

type SizeElementType = {
  [key in SizeType]: string;
};

interface TitleProps extends TypographyType {
  size?: SizeType;
}

export const Title = ({
  children,
  size = 'large',
  customSize,
  customWeight,
  customLineHeight,
  customFontFamily,
  as,
  ...rest
}: TitleProps) => {
  const theme = useTheme();
  const fontSizeMap = {
    large: 18,
    medium: 16,
    small: 14,
    xs: 12,
  };

  const element = {
    large: 'h1',
    medium: 'h2',
    small: 'h3',
    xs: 'h4',
  } as SizeElementType;

  return (
    <StyledTitle
      fontFamily={customFontFamily || theme.fonts.family.secondary}
      fontSize={customSize || fontSizeMap[size]}
      fontWeight={customWeight || theme.fonts.weight.medium}
      lineHeight={customLineHeight}
      as={as || element[size]}
      {...rest}
    >
      {children}
    </StyledTitle>
  );
};
