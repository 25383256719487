import { useTheme } from 'styled-components';

import { TypographyType } from '../types';
import { StyledTable } from './styles';

type SizeType = 'large' | 'medium' | 'small';

interface TableProps extends TypographyType {
  size?: SizeType;
}

export const Table = ({
  children,
  size = 'large',
  customSize,
  customWeight,
  customLineHeight,
  customFontFamily,
  ...rest
}: TableProps) => {
  const theme = useTheme();
  const fontSizeMap = {
    large: theme.fonts.size.base,
    medium: theme.fonts.size.sm1,
    small: theme.fonts.size.xs1,
  };

  return (
    <StyledTable
      fontSize={customSize || fontSizeMap[size]}
      fontFamily={customFontFamily || theme.fonts.family.secondary}
      fontWeight={customWeight}
      lineHeight={customLineHeight}
      {...rest}
    >
      {children}
    </StyledTable>
  );
};
